
export default {
  //transfert_siege_hors_ressort_nouveau_departement_societe_civile
  id: 'transfert-siege-nouveau-departement-commerciale',
  label: 'Transfert de siège hors ressort - Nouveau département (société commerciale)',
  shortLabel: 'Transfert de siège\nsocial',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: { type: '', actor: '' },
    modification: {},
    ownerList: [],
    chairmanList: [],
    ceoList: [],
    chiefExecutiveList: [],
    assistantChiefExecutiveList: [],
    otherMemberList: [],
    mention: { registry: 'Radiation du RCS [Ancien greffe] et immatriculation au RCS [Nouveau greffe]' }
  },
  component: () => import('@/components/legal-ads/forms/models/modification/transfer-new-state-2.vue'),
};
