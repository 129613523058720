import * as Modification from '@/services/legal-ad-form/components/modification';
import * as Company from '@/services/legal-ad-form/components/modification/company';
import * as Decision from '@/services/legal-ad-form/components/modification/decision';
import * as HeadOffice from '@/services/legal-ad-form/components/modification/head-office';
import * as Mention from '@/services/legal-ad-form/components/modification/mention';
import * as Person from '@/services/legal-ad-form/components/modification/person';
import { trimObject } from '@/shared/utils';

export default {
  id: 'transfert-siege',
  label: 'Transfert de siège même ressort (société civile/société commerciale)',
  shortLabel: 'Transfert de siège\nsocial',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: { type: '', actor: '' },
    modification: {},
    mention: { registry: 'Mention sera portée au RCS de ...(à compléter)' }
  },
  component: () => import('@/components/legal-ads/forms/models/modification/transfer.vue'),
  validate(modelData) {
    return trimObject({
      company: Company.validate(modelData.company),
      headOffice: HeadOffice.validate(modelData.headOffice),
      decision: Decision.validate(modelData.decision),
      modification: Modification.validateTransfer(modelData.modification),
      ownerList: Person.validateMany(modelData.ownerList, true),
      chairmanList: Person.validateMany(modelData.chairmanList, false),
      ceoList: Person.validateMany(modelData.ceoList, false),
      chiefExecutiveList: Person.validateMany(modelData.chiefExecutiveList, false),
      assistantChiefExecutiveList: Person.validateMany(modelData.assistantChiefExecutiveList, false),
      otherMemberList: Person.validateMany(modelData.otherMemberList, false),
      mention: Mention.validate(modelData.mention),
    });
  },
  buildContent(modelData) {
    return [
      Company.buildContent(modelData.company, { withShareCapital: true }),
      HeadOffice.buildContent(modelData.headOffice),
      Modification.buildTransferContent(modelData),
      Modification.buildSocialObjectContent(modelData),
      Person.buildManyContent(modelData.ownerList, 'Gérant'),
      Person.buildManyContent(modelData.chairmanList, 'Président'),
      Person.buildManyContent(modelData.ceoList, 'Président directeur général'),
      Person.buildManyContent(modelData.chiefExecutiveList, 'Directeur général'),
      Person.buildManyContent(modelData.assistantChiefExecutiveList, 'Directeur général délégué'),
      Person.buildManyContent(modelData.otherMemberList, 'Personne ayant le pouvoir d\'engager la société'),
      Mention.buildContent(modelData.mention)
    ].flat().join("\n").replace(/undefined/g, '?');
  }
};
