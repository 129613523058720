
export default {
  id: 'transfert-siege-ancien-departement',
  label: 'Transfert de siège hors ressort - Ancien Département (société civile/société commerciale)',
  shortLabel: 'Transfert de siège\nsocial',
  componentsData: {
    company: { legalForm: '' },
    headOffice: {},
    decision: { type: '', actor: '' },
    modification: {},
    mention: { registry: 'Radiation du RCS [Ancien greffe] et immatriculation au RCS [Nouveau greffe]' }
  },
  component: () => import('@/components/legal-ads/forms/models/modification/transfer-old-state.vue'),
};
